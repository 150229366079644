<template>
    <div class="app-packages-list">
        <app-loader v-if="loading"></app-loader>

        <h2 class="heading">{{ title }}</h2>

        <div class="packages-list">
            <app-package
                v-for="item in packages"

                :key="item.UUID"

                :title="item.Title"
                :description="item.Description"
                :cents="item.Cents"
                :image-name="item.ImageName"
            />
        </div>
    </div>
</template>

<script>
import appLoader from '@/components/app-loader'
import appPackage from './components/app-package'

export default {
    components: {
        appPackage,
        appLoader,
    },

    props: {
        title:    { type: String,  default: 'TV Packages' },
        packages: { type: Array,   required: true         },
        loading:  { type: Boolean, default: false         },
    },
}
</script>

<style lang="scss">
.app-packages-list {
    position: relative;

    width: 100%;

    padding: 24px 24px 8px;

    background-color: var(--color-component-bg-primary);
    border-radius: $border-radius-primary;
    box-shadow: var(--box-shadow-primary);

    .packages-list {
        margin-top: 8px;

        .app-package {
            box-shadow: 0 1px 0 var(--color-divider);

            &:last-child {
                box-shadow: 0 1px 0 transparent;
            }
        }
    }
}

@media (max-width: $tablet-size) {
    .app-packages-list {
        padding: 24px 8px 0;

        h2 {
            padding-left: 8px;
        }
    }
}

@media (max-width: $mobile-size) {
    .app-packages-list {
        padding: 16px 8px 0;

        h2 {
            padding-left: 0;

            font-size: 24px;
            line-height: 32px;
        }
    }
}
</style>