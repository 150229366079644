<template>
    <div class="app-package">
        <div class="main-info">
            <img :src="src">

            <div class="details">
                <h3 class="heading">{{ title }}</h3>
                <div class="price">{{ price }}</div>
            </div>
        </div>

        <div class="description">{{ description }}</div>
    </div>
</template>

<script>
export default {
    props: {
        title:       { type: String, required: true },
        description: { type: String, required: true },
        cents:       { type: Number, required: true },
        imageName:   { type: String, required: true },
    },

    data() {
        return {
            src: null,
        }
    },

    computed: {
        price() {
            return `$${ ( this.cents / 100 ).toFixed(2) } / month`
        },
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            import(`./img/${ this.imageName }`)
                .then(src => {
                    this.src = src.default
                })
                .catch(error => {
                    console.log(error)

                    /**
                     * @todo show default image
                     */
                })
        },
    },
}
</script>

<style lang="scss">
.app-package {
    display: flex;
    flex-wrap: wrap;

    padding: 16px 0;

    font-size: 16px;
    line-height: 24px;

    box-shadow: 0 1px 0 transparent;

    .main-info {
        display: flex;
        flex: 0 0 33%;
        max-width: 33%;

        img {
            display: block;
            width: 102px;
            height: 64px;
            object-fit: contain;
            border-radius: $border-radius-primary;
        }

        .details {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: calc(100% - 102px);
            padding: 0 30px 0 24px;

            h3 {
                @include text-overflow();
            }

            .price {
                color: #999; /* @todo Создать отдельную переменную */
            }
        }
    }

    .description {
        flex: 0 0 66%;
        max-width: 66%;
        margin: 4px 0;
    }
}

@media (max-width: $tablet-size) {
    .app-package {
        .main-info,
        .description {
            flex: 0 0 100%;
            max-width: 100%;
        }

        .main-info {
            .details {
                padding: 0 0 0 24px;
            }
        }

        .description {
            margin: 16px 0 0;
        }
    }
}

@media (max-width: $mobile-size) {
    .app-package {
        padding: 8px 0;

        font-size: 14px;

        .main-info {
            img {
                width: 90px;
                height: 56px;
            }

            .details {
                padding: 0 0 0 16px;

                h3 {
                    font-size: 18px;
                }
            }
        }

        .description {
            margin: 8px 0 0;
        }
    }
}
</style>